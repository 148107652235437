/* eslint-disable camelcase */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addQuarters } from "date-fns";
import zipWith from "ramda/src/zipWith";
import { plainToClass } from "class-transformer";

import { ActivitiesAPI } from "api/activities";
import { CustomersAPI, CustomerSearchByEnum } from "api/customers";
import { DeliveryPlanAPI } from "api/deliveryPlan";
import { authMethod } from "auth";
import { EMPTY_UUID } from "libs/uuid";
import { CustomerActivitiesServices } from "models/activities/activity";
import {
  CreateCustomer,
  Customer,
  CustomerSettings,
  CustomersLudvigServiceStatus,
  SieFile,
  VariableKeyObject,
} from "models/customer";
import {
  CustomerDelivery,
  CustomerPackage,
  CustomerTaxObjects,
  DeletedDelivery,
  DeliveryFact,
  DeliveryRecurrence,
} from "models/deliveryPlan";
import { LudvigServiceFeedback } from "models/ludvigAutomationService";
import { PersonDetails } from "models/profile";
import { MyThunkAction, RootState } from "state";
import {
  ActivitiesAction,
  SET_AVAILABLE_SERVICES,
  SET_AVAILABLE_SERVICES_LOADING,
} from "state/activities";
import {
  appendServiceActivity,
  fetchActivitiesForCustomers,
  fetchActivitiesForCustomersV2,
  fetchActivitiesServicesForCustomers,
  fetchUnfinishedActivities,
  getActivitiesTeam,
} from "state/activities/actions";
import { appendError, appendToastMessage } from "state/notifications";
import { CustomerState } from "./reducer";
import { CustomersAction } from ".";
import * as ActionTypes from ".";

export const GENERAL_ACCOUNTING_DELIVERY = "General accounting";
export const YEAR_END_DELIVERY = "Year end";

export const CREATED_USING_CB = "Conveyor Belt";
export const CREATED_USING_DP = "Delivery Planner";

export function createNewCustomer(
  newCustomer: CreateCustomer
): MyThunkAction<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(stateLoadingNewCustomer(true));

    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.createCustomer(token, newCustomer);
      dispatch(stateLoadingNewCustomer(false));
      return true;
    } catch (e) {
      dispatch(appendError("FAILED_TO_CREATE_CUSTOMER", e as Error));
      dispatch(stateLoadingNewCustomer(false));
      throw e;
    }
  };
}

export const fetchCustomerDeliveryPlan = createAsyncThunk(
  "customers/FETCH_DELIVERY_PLAN",
  async ({ customer }: { customer: Customer }, { dispatch }) => {
    try {
      dispatch(
        stateCustomerState(customer, {
          hasLoadedDeliveryPlan: true,
        })
      );
      const token = await authMethod.getStoredAccessToken();
      dispatch(stateCustomerLoading(customer, true));
      const deliveryPlan = await DeliveryPlanAPI.fetchCustomerDeliveryPlan(
        token,
        customer
      );
      dispatch(
        statePartialCustomer(customer.customer_number, {
          deliveryPlan,
        })
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DELIVERY_PLAN", e as Error));
      throw e;
    } finally {
      dispatch(stateCustomerLoading(customer, false));
    }
  }
);

export const addTemporaryAccessToCustomer = createAsyncThunk(
  "customers/ADD_TEMPORARY_ACCESS_TO_CUSTOMER",
  async (
    {
      customerId,
      customerName,
      isSupportUser,
    }: { customerId: string; customerName: string; isSupportUser: boolean },
    { dispatch }
  ) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const response = await CustomersAPI.addTemporaryAccessToCustomer(
        token,
        customerId,
        isSupportUser
      );
      dispatch(
        appendToastMessage(
          "SUCCESSFULLY_GRANTED_TEMPORARY_ACCESS_TO_CUSTOMER",
          "success",
          [customerName]
        )
      );
      return response;
    } catch (e) {
      dispatch(
        appendError("FAILED_TO_GRANT_TEMPORARY_ACCESS_TO_CUSTOMER", e as Error)
      );
      throw e;
    }
  }
);

export const createOrUpdateYearlyRecurringDeliveries = createAsyncThunk(
  "customers/CREATE_OR_UPDATE_YEARLY_RECURRING_DELIVERIES",
  async (
    {
      customer,
      packages,
      startYearDate,
      yearlyDeliveryDate,
      recurrence,
      deliveryType,
      deliveryDates,
      createdUsing,
      lock_in,
    }: {
      customer: Customer;
      packages: CustomerPackage[];
      startYearDate: Date;
      yearlyDeliveryDate: Date;
      recurrence: DeliveryRecurrence;
      deliveryType: string;
      deliveryDates: Date[];
      createdUsing: string;
      lock_in: boolean;
    },
    { dispatch, getState }
  ) => {
    const { users } = getState() as RootState;
    const existingDeliveries = customer.deliveryPlan.filter(
      (dp) => dp.delivery_name === deliveryType && dp?.ends_at == null
    );
    const yearDates = [
      startYearDate.getFullYear(),
      startYearDate.getFullYear() + 1,
      startYearDate.getFullYear() + 2,
    ].map((year) => {
      const date = new Date(yearlyDeliveryDate);
      date.setFullYear(year);
      date.setMonth(yearlyDeliveryDate.getMonth());
      date.setDate(yearlyDeliveryDate.getDate());
      return date;
    });

    let all_delivery_dates: Date[];
    if (deliveryDates.length > 0) {
      all_delivery_dates = [
        deliveryDates[0].getFullYear(),
        deliveryDates[0].getFullYear() + 1,
        deliveryDates[0].getFullYear() + 2,
      ].map((year) => {
        const date = new Date(deliveryDates[0]);
        date.setFullYear(year);
        date.setMonth(deliveryDates[0].getMonth());
        date.setDate(deliveryDates[0].getDate());
        return date;
      });
    } else {
      all_delivery_dates = [];
    }
    let i = 0;
    if (
      existingDeliveries.length === 0 ||
      existingDeliveries.every((delivery) => delivery?.ends_at != null)
    ) {
      // Create new deliveries if there are no existing deliveries
      await Promise.all(
        yearDates.map((year) => {
          const startDate = year;
          if (all_delivery_dates.length > 0) {
            if (recurrence === "QUARTERLY") {
              let startMonth = 5;
              const startDateMonth = startDate.getMonth() + 1;

              if ([4, 5, 6].includes(startDateMonth)) {
                startMonth = 8;
              } else if ([7, 8, 9].includes(startDateMonth)) {
                startMonth = 11;
              } else if ([10, 11, 12].includes(startDateMonth)) {
                startDate.setFullYear(startDate.getFullYear() + 1);
                startMonth = 2;
              }

              const firstPlannedDate = new Date(startDate);
              firstPlannedDate.setMonth(startMonth - 1);

              deliveryDates = [
                firstPlannedDate,
                addQuarters(firstPlannedDate, 1),
                addQuarters(firstPlannedDate, 2),
                addQuarters(firstPlannedDate, 3),
              ];
            } else {
              deliveryDates = [all_delivery_dates[i]] || [];
            }
            i++;
          }

          const delivery = new CustomerDelivery(
            deliveryType,
            packages,
            users.currentUser.graphId,
            startDate,
            recurrence,
            lock_in,
            createdUsing,
            undefined,
            deliveryDates
          );

          return dispatch(createCustomerDelivery({ customer, delivery }));
        })
      );
    } else {
      // Zip together updates with the dates
      await Promise.all(
        zipWith(
          (date, delivery) => {
            delivery.created_by_user_uuid = users.currentUser.graphId;
            let deliveryDate;
            if (all_delivery_dates.length > 0) {
              deliveryDate = [all_delivery_dates[i]];
              i++;
            }
            return dispatch(
              updateCustomerDelivery({
                customer,
                deliveryId: delivery.uuid,
                update: new CustomerDelivery(
                  deliveryType,
                  packages,
                  users.currentUser.graphId,
                  date,
                  recurrence,
                  lock_in,
                  createdUsing,
                  undefined,
                  deliveryDate,
                  delivery.uuid
                ),
              })
            );
          },
          yearDates,
          existingDeliveries
        )
      );
    }
  }
);

export const createCustomerDelivery = createAsyncThunk(
  "customers/CREATE_DELIVERY",
  async (
    {
      customer,
      delivery,
    }: {
      customer: Customer;
      delivery: Partial<CustomerDelivery>;
    },
    { dispatch }
  ) => {
    try {
      // For optimistically updating the UI, set the UUID to an empty UUID
      const newDelivery = plainToClass(CustomerDelivery, {
        ...delivery,
        uuid: EMPTY_UUID,
        isLoading: true,
        delivery_dates: delivery.delivery_dates,
      });

      dispatch(stateCustomerLoading(customer, true));
      dispatch(stateCreateDelivery(customer, newDelivery));

      const token = await authMethod.getStoredAccessToken();
      const customerDelivery = await DeliveryPlanAPI.createCustomerDelivery(
        token,
        customer,
        newDelivery
      );

      // Replace with actual ID
      dispatch(
        stateUpdateDelivery(customer, newDelivery.uuid, {
          uuid: customerDelivery.uuid,
          isLoading: false,
          delivery_dates: customerDelivery.delivery_dates,
        })
      );

      return customerDelivery;
    } catch (e) {
      dispatch(stateDeleteDelivery(customer, EMPTY_UUID));
      dispatch(appendError("FAILED_TO_CREATE_DELIVERY", e as Error));
    } finally {
      dispatch(updateCustomerDetails({ customer }));
    }
  }
);

export const updateCustomerDetails = createAsyncThunk(
  "customers/UPDATE_CUSTOMER_DETAILS",
  async ({ customer }: { customer: Customer }, { dispatch }) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const customerToUpdate = await ActivitiesAPI.fetchCustomerByOrgNr(
        token,
        customer.company_registration_number
      );
      dispatch(
        statePartialCustomer(customer.customer_number, {
          frequency: customerToUpdate.frequency,
          special_accounting_package:
            customerToUpdate.special_accounting_package,
          standard_accounting_package:
            customerToUpdate.standard_accounting_package,
          general_accounting: customerToUpdate.general_accounting,
        })
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_CUSTOMER_DETAILS", e as Error));
    } finally {
      dispatch(stateCustomerLoading(customer, false));
    }
  }
);

export const fetchAvailableServices = createAsyncThunk(
  "customers/FETCH_AVAILABLE_SERVICES",
  async ({ customer }: { customer: Customer }, { dispatch }) => {
    try {
      dispatch(stateAvailableServicesLoading(true));
      const token = await authMethod.getStoredAccessToken();
      const availableServices = await DeliveryPlanAPI.fetchAvailableDeliveries(
        token,
        customer
      );
      dispatch(stateAvailableServices(availableServices));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_AVAILABLE_SERVICES", e as Error));
      throw e;
    } finally {
      dispatch(stateAvailableServicesLoading(false));
    }
  }
);

export const updateCustomerDelivery = createAsyncThunk(
  "customers/UPDATE_DELIVERY",
  async (
    {
      customer,
      deliveryId,
      update,
    }: {
      customer: Customer;
      deliveryId: string;
      update: Partial<CustomerDelivery>;
    },
    { dispatch }
  ) => {
    try {
      dispatch(stateCustomerLoading(customer, true));
      dispatch(stateUpdateDelivery(customer, deliveryId, update));

      const token = await authMethod.getStoredAccessToken();

      const updatedDelivery = await DeliveryPlanAPI.updateCustomerDelivery(
        token,
        customer,
        deliveryId,
        update
      );
      dispatch(
        stateUpdateDelivery(customer, updatedDelivery.uuid, {
          uuid: updatedDelivery.uuid,
          isLoading: false,
          delivery_dates: updatedDelivery.delivery_dates,
        })
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DELIVERY", e as Error));
    } finally {
      dispatch(stateCustomerLoading(customer, false));
    }
  }
);

export const executeCustomerDeliveryPlan = createAsyncThunk(
  "customer/EXECUTE_DELIVERY_PLAN",
  async (
    {
      customer,
      deliveryPlan,
    }: {
      customer: Customer;
      deliveryPlan: CustomerDelivery[];
    },
    { dispatch }
  ) => {
    try {
      dispatch(stateCustomerLoading(customer, true));

      const token = await authMethod.getStoredAccessToken();

      await Promise.all(
        deliveryPlan.map((d) =>
          DeliveryPlanAPI.updateCustomerDelivery(token, customer, d.uuid, {
            is_locked_in: true,
          })
        )
      );

      await deliveryPlan.map((d) =>
        dispatch(stateUpdateDelivery(customer, d.uuid, { is_locked_in: true }))
      );
      await dispatch(fetchActivitiesServicesForCustomers({}));
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DELIVERY", e as Error));
    } finally {
      dispatch(updateCustomerDetails({ customer }));
    }
  }
);

export const deleteCustomerDelivery = createAsyncThunk(
  "customers/DELETE_DELIVERY",
  async (
    {
      customer,
      deliveryId,
      endDate,
    }: {
      customer: Customer;
      deliveryId: string;
      endDate?: Date;
    },
    { dispatch }
  ) => {
    try {
      dispatch(stateUpdateDelivery(customer, deliveryId, { isLoading: true }));
      dispatch(stateDeleteDelivery(customer, deliveryId));

      const token = await authMethod.getStoredAccessToken();

      await DeliveryPlanAPI.deleteCustomerDelivery(
        token,
        customer,
        deliveryId,
        endDate
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_DELETE_DELIVERY", e as Error));
    } finally {
      dispatch(stateUpdateDelivery(customer, deliveryId, { isLoading: false }));
    }
  }
);

export const deleteCustomerDeliveriesBatch = createAsyncThunk(
  "customers/DELETE_DELIVERIES_BATCH",
  async (
    {
      customer,
      deliveryIds,
      endDate,
    }: {
      customer: Customer;
      deliveryIds: string[];
      endDate?: Date;
    },
    { dispatch }
  ) => {
    try {
      const token = await authMethod.getStoredAccessToken();

      const response = await DeliveryPlanAPI.deleteCustomerDeliveriesBatch(
        token,
        customer,
        deliveryIds,
        endDate
      );
      dispatch(stateDeleteDeliveriesBatch(customer, response));
    } catch (e) {
      dispatch(appendError("FAILED_TO_DELETE_DELIVERY", e as Error));
    }
  }
);

export function createLudvigServiceFeedback(
  customer: Customer,
  ludvigServiceFeedback: LudvigServiceFeedback
): MyThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(stateLoadingCustomers(true));

    let service = customer.ludvig_service.find(
      (l) => l.ServiceType === ludvigServiceFeedback.ServiceType
    );

    if (!service) {
      service = getState()
        .customers.customer_ludvig_services.find(
          (c) => c.customer_number === customer.customer_number
        )
        ?.ludvig_service_statuses.find(
          (s) => s.ServiceType === ludvigServiceFeedback.ServiceType
        );
      if (!service) {
        throw new Error("Could not find matching service");
      }
    }

    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.createLudvigServiceFeedback(
        token,
        customer,
        ludvigServiceFeedback
      );

      service.NeedsFeedback = false;

      dispatch(
        statePartialCustomer(customer.customer_number, {
          ludvig_service: [...customer.ludvig_service, service],
        })
      );
      dispatch(stateLoadingCustomers(false));
      return;
    } catch (e) {
      dispatch(appendError("FAILED_TO_CREATE_FEEDBACK", e as Error));
      dispatch(stateLoadingCustomers(false));
      throw e;
    }
  };
}

export function removeCustomerActivities(customer: Customer): MyThunkAction {
  return async (dispatch) => {
    dispatch(stateCustomerLoading(customer, true));

    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.removeCustomerActivities(
        token,
        customer.customer_number
      );

      dispatch(
        appendServiceActivity(
          plainToClass(CustomerActivitiesServices, {
            active: false,
            assigned_users: {
              activities_users: [],
              client_user: undefined,
            },
            fiscal_year_is_missing: customer.fiscalYearMonthEnd === undefined,
            customer_id: customer.customer_number,
            org_number: customer.company_registration_number,
            services: [],
          })
        )
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_CUSTOMER", e as Error));
    } finally {
      dispatch(stateCustomerLoading(customer, false));
    }
  };
}

export function updateCustomerSettings(
  customer: Customer,
  update: Partial<CustomerSettings>
): MyThunkAction {
  return async (dispatch) => {
    dispatch(dispatch(stateCustomerLoading(customer, true)));

    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.updateCustomerSettings(
        token,
        customer.customer_number,
        update
      );

      const settings = new CustomerSettings({
        ...customer.settings,
        ...update,
      });

      dispatch(
        statePartialCustomer(customer.customer_number, {
          settings,
        })
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_CUSTOMER", e as Error));
    } finally {
      dispatch(stateCustomerLoading(customer, false));
    }
  };
}

export function updateCustomer(
  customer: Customer,
  update: Partial<Customer>
): MyThunkAction {
  return async (dispatch) => {
    dispatch(dispatch(stateCustomerLoading(customer, true)));

    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.updateCustomer(
        token,
        customer.customer_number,
        update
      );

      dispatch(statePartialCustomer(customer.customer_number, update));
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_CUSTOMER", e as Error));
    } finally {
      dispatch(stateCustomerLoading(customer, false));
    }
  };
}

export function createCustomerTeam(customer: Customer): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(
        statePartialCustomer(customer.customer_number, {
          apps: {
            ...customer.apps,
            teams: {
              status: "loading",
              updated: new Date(),
              created: new Date(),
            },
          },
        })
      );

      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.createCustomerTeam(token, customer.customer_number);
    } catch (e) {
      dispatch(
        statePartialCustomer(customer.customer_number, {
          apps: {
            ...customer.apps,
            teams: {
              status: "nonexistent",
              updated: undefined,
              created: undefined,
            },
          },
        })
      );

      dispatch(appendError("FAILED_TO_CREATE_CUSTOMER_TEAM", e as Error));
    }
  };
}
export function fetchCustomerTeamsStatuses(
  customers: Customer[]
): MyThunkAction<Promise<VariableKeyObject | undefined>> {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const customersTeamsStatus =
        await CustomersAPI.fetchCustomersTeamsStatuses(token, customers);
      return customersTeamsStatus;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_CUSTOMERS_TEAMS", e as Error));
    }
  };
}

export function fetchTeamsFilesTabLink(
  customer: Customer
): MyThunkAction<Promise<string | undefined>> {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      dispatch(stateCustomerLoading(customer, true));
      const filesLink = await CustomersAPI.fetchTeamsFilesTabLink(
        token,
        customer.customer_number
      );
      dispatch(
        statePartialCustomer(customer.customer_number, {
          ...customer,
          teamsFilesTabLink: filesLink,
        })
      );
      dispatch(stateCustomerLoading(customer, false));
      return filesLink || "";
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_CUSTOMERS_TEAMS", e as Error));
    }
  };
}

export function fetchCustomerLudvigServices(
  customerNumber: string
): MyThunkAction {
  return async (dispatch) => {
    const token = await authMethod.getStoredAccessToken();
    const customersLudvigServices =
      await CustomersAPI.fetchCustomersLudvigServices(token, [customerNumber]);
    dispatch(updateCustomersLudvigServices(customersLudvigServices));
  };
}

export function fetchCustomers(
  searchTerm?: string,
  basicPopulate?: boolean,
  appendAndPopulate?: boolean,
  postLoad = true
): MyThunkAction {
  return async (dispatch, getState) => {
    const failedToFetchUsers: string[] = [];
    try {
      if (basicPopulate || appendAndPopulate) {
        dispatch(stateLoadingCustomers());
      }
      const token = await authMethod.getStoredAccessToken();

      const customers = basicPopulate
        ? await CustomersAPI.fetchCustomers(token, {
            searchTerm,
            populate: "True",
          })
        : await CustomersAPI.fetchCustomers(token, {
            searchTerm,
            skipServices: "True",
          });

      if (searchTerm) {
        const newCustomers = customers.filter(
          (cc) =>
            !getState().customers.data.some(
              (c) => c.customer.customer_number === cc.customer_number
            )
        );

        if (newCustomers.length > 0) {
          dispatch(
            fetchActivitiesForCustomers(
              newCustomers.map((customer) => customer.customer_number)
            )
          );
        }
      }
      const customersExtended = await customers.map(async (customer) => {
        return {
          customer,
          isFromSearch: !!searchTerm && searchTerm.length > 0,
          isLoading: false,
          hasLoaded: true,
          hasLoadedDeliveryPlan: false,
        };
      });
      const awaitedCustomers = await Promise.all(customersExtended);
      if (appendAndPopulate) {
        dispatch(stateCustomers(awaitedCustomers));
      }
      if (basicPopulate) {
        dispatch(stateCustomers(awaitedCustomers));
      }
      if (!appendAndPopulate && !basicPopulate) {
        dispatch(stateCustomers(awaitedCustomers));
      }
      // TODO remove it after the customer overview is moved completely in CB
      // ML-852 ML-853 and ML-855
      // check if delivery plan is needed in CB or only in delivery planner
      if (postLoad) {
        dispatch(fetchCustomersTeamStatus(searchTerm));
        dispatch(fetchCustomersDeliveryPlan(searchTerm));
      }
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_CUSTOMERS", e as Error));
      dispatch(stateLoadingCustomersFailed());
    } finally {
      if (failedToFetchUsers.length > 0) {
        dispatch(
          appendError(
            "FAILED_TO_FETCH_USER",
            new Error(failedToFetchUsers.toString())
          )
        );
      }
    }
  };
}

export function fetchCustomersV2(postLoad = true): MyThunkAction {
  return async (dispatch) => {
    const failedToFetchUsers: string[] = [];
    try {
      dispatch(stateLoadingCustomers());

      const token = await authMethod.getStoredAccessToken();
      const customers = await CustomersAPI.fetchCustomers(token, {
        skipServices: "True",
      });

      const customersExtended = customers.map((customer) => {
        return {
          customer,
          isFromSearch: false,
          isLoading: false,
          hasLoaded: true,
          hasLoadedDeliveryPlan: false,
        };
      });
      dispatch(stateCustomers(customersExtended));

      // TODO remove it after the customer overview is moved completely in CB
      // ML-852 ML-853 and ML-855
      // check if delivery plan is needed in CB or only in delivery planner
      if (postLoad) {
        dispatch(fetchCustomersTeamStatus());
      }
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_CUSTOMERS", e as Error));
      dispatch(stateLoadingCustomersFailed());
    } finally {
      if (failedToFetchUsers.length > 0) {
        dispatch(
          appendError(
            "FAILED_TO_FETCH_USER",
            new Error(failedToFetchUsers.toString())
          )
        );
      }
    }
  };
}

export function fetchSearchCustomersV2(
  searchTerm?: string,
  searchBy?: CustomerSearchByEnum,
  postLoad = true
): MyThunkAction {
  return async (dispatch, getState) => {
    const failedToFetchUsers: string[] = [];
    try {
      dispatch(stateLoadingCustomers());

      const token = await authMethod.getStoredAccessToken();
      const customers = await CustomersAPI.fetchCustomers(token, {
        searchTerm,
        searchBy,
        skipServices: "True",
      });

      const filteredCustomerNumbers = customers
        .filter(
          (customer) =>
            !getState().customers.data.some(
              (customerState) =>
                customerState.customer.customer_number ===
                customer.customer_number
            )
        )
        .map((customer) => customer.customer_number);
      if (filteredCustomerNumbers.length > 0) {
        dispatch(getActivitiesTeam(filteredCustomerNumbers));
        dispatch(fetchUnfinishedActivities(filteredCustomerNumbers));
        dispatch(fetchActivitiesForCustomersV2(filteredCustomerNumbers));
      }

      const customersExtended = customers.map((customer) => {
        return {
          customer,
          isFromSearch: true,
          isLoading: false,
          hasLoaded: true,
          hasLoadedDeliveryPlan: false,
        };
      });

      dispatch(setSearchCustomers(customersExtended));

      // TODO remove it after the customer overview is moved completely in CB
      // ML-852 ML-853 and ML-855
      // check if delivery plan is needed in CB or only in delivery planner
      if (postLoad) {
        dispatch(fetchCustomersTeamStatus(searchTerm));
      }
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_CUSTOMERS", e as Error));
      dispatch(stateLoadingCustomersFailed());
    } finally {
      if (failedToFetchUsers.length > 0) {
        dispatch(
          appendError(
            "FAILED_TO_FETCH_USER",
            new Error(failedToFetchUsers.toString())
          )
        );
      }
    }
  };
}

export function fetchCustomersTeamStatus(searchTerm?: string): MyThunkAction {
  return async (dispatch, getState) => {
    const { customers } = getState();

    const mappedCustomersFromState = customers.data.map((c) => c.customer);

    const customersTeamsStatus: VariableKeyObject | undefined = await dispatch(
      fetchCustomerTeamsStatuses(mappedCustomersFromState)
    );

    const customersExtended = await mappedCustomersFromState.map(async (c) => {
      const customer = c;

      if (customersTeamsStatus) {
        customer.teamsStatus = customersTeamsStatus[`${c.customer_number}`];
      }

      return {
        customer,
        isFromSearch: !!searchTerm && searchTerm.length > 0,
        isLoading: false,
        hasLoaded: true,
        hasLoadedDeliveryPlan: false,
      };
    });

    const awaitedCustomers = await Promise.all(customersExtended);
    dispatch(stateCustomers(awaitedCustomers));
  };
}

export function fetchCustomersDeliveryPlan(searchTerm?: string): MyThunkAction {
  return async (dispatch, getState) => {
    const token = await authMethod.getStoredAccessToken();
    const { customers } = getState();

    const mappedCustomersFromState = customers.data.map((c) => c.customer);
    const customersDeliveryPlans =
      await DeliveryPlanAPI.fetchCustomerDeliveryPlanBatch(
        token,
        mappedCustomersFromState
      );

    const customersExtended = await mappedCustomersFromState.map(async (c) => {
      const customer = c;

      customer.deliveryPlan =
        customersDeliveryPlans.get(c.customer_number) ?? [];

      return {
        customer,
        isFromSearch: !!searchTerm && searchTerm.length > 0,
        isLoading: false,
        hasLoaded: true,
        hasLoadedDeliveryPlan: false,
      };
    });

    const awaitedCustomers = await Promise.all(customersExtended);
    dispatch(stateCustomers(awaitedCustomers));
  };
}

export function updateAssignmentManagers(
  customer: Customer,
  managers: PersonDetails[]
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.setAssignmentManagers(
        token,
        customer.customer_number,
        customer.assignments[0].project_number,
        managers
      );

      dispatch(stateAssignmentManagers(customer, managers));
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_ASSIGNMENT_MANAGERS", e as Error));
    }
  };
}

export function fetchCustomerSieFiles(customer: Customer): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const sieFiles = await CustomersAPI.getCustomerSieFiles(
        token,
        customer.company_registration_number
      );
      dispatch(stateCustomerSieFiles(customer, sieFiles));
    } catch (e) {
      dispatch(appendError("FAILED_TO_GET_SIE_FILES", e as Error));
    }
  };
}

export function fetchCustomerDeferredPayments(
  customerNumber: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const deferredPayments = await CustomersAPI.fetchCustomerDeferredPayments(
        token,
        customerNumber
      );
      return deferredPayments;
    } catch (e) {
      dispatch(appendError("FAILED_TO_GET_DEFERRED_PAYMENTS", e as Error));
    }
  };
}

export function updateCustomerDeferredPayments(
  customerNumber: string,
  postponedInvoiceData: JSON
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.updateCustomerDeferredPayments(
        token,
        customerNumber,
        postponedInvoiceData
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEFERRED_PAYMENTS", e as Error));
    }
  };
}

export function updateCustomerSieFilePriority(
  customer: Customer,
  sieFile: SieFile
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(stateSieFilePriorityLoading(customer, sieFile, true));
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.updateCustomerSieFilePriority(
        token,
        sieFile.bucket,
        sieFile.key,
        customer.company_registration_number.toString()
      );
      dispatch(stateUpdateCustomerFieFilePriority(customer, sieFile));
    } catch (e) {
      dispatch(appendError("FAILED_TO_PRIORITIZE_SIE_FILE", e as Error));
    } finally {
      dispatch(stateSieFilePriorityLoading(customer, sieFile, false));
    }
  };
}

export const fetchCustomerTaxObjects = createAsyncThunk(
  "customers/FETCH_CUSTOMER_TAX_OBJECTS",
  async (customerNumber: string, { dispatch }) => {
    try {
      dispatch(stateCustomerTaxObjectsLoading(true));
      const token = await authMethod.getStoredAccessToken();
      const taxObjects = await DeliveryPlanAPI.fetchCustomerTaxObjects(
        token,
        customerNumber
      );
      dispatch(stateGetCustomerTaxObjects(taxObjects, customerNumber));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_TAX_OBJECTS", e as Error));
      throw e;
    } finally {
      dispatch(stateCustomerTaxObjectsLoading(false));
    }
  }
);

export function setSearchCustomers(
  customers: CustomerState[]
): CustomersAction {
  return {
    type: ActionTypes.SET_SEARCH_CUSTOMERS,
    payload: customers,
  };
}

export function stateCustomerTaxObjectsLoading(
  loading: boolean
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMER_TAX_OBJECTS_LOADING,
    payload: loading,
  };
}

export function stateGetCustomerTaxObjects(
  customerTaxObjects: CustomerTaxObjects,
  customerNumber: string
): CustomersAction {
  return {
    type: ActionTypes.GET_CUSTOMER_TAX_OBJECTS,
    payload: {
      taxObjects: customerTaxObjects,
      customerNumber,
    },
  };
}

export function stateSieFilePriorityLoading(
  customer: Customer,
  sieFile: SieFile,
  loading: boolean
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMER_SIE_FILE_PRIORITIZE_LOADING,
    payload: {
      customer,
      sieFile,
      loading,
    },
  };
}

export function stateUpdateCustomerFieFilePriority(
  customer: Customer,
  sieFile: SieFile
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMER_SIE_FILE_PRIORITY,
    payload: {
      customer,
      sieFile,
    },
  };
}

export function stateAvailableServices(
  services: DeliveryFact[]
): ActivitiesAction {
  return {
    type: SET_AVAILABLE_SERVICES,
    payload: services,
  };
}

export function stateAvailableServicesLoading(
  isLoading: boolean
): ActivitiesAction {
  return {
    type: SET_AVAILABLE_SERVICES_LOADING,
    payload: isLoading,
  };
}

export function stateCustomerSieFiles(
  customer: Customer,
  sieFiles: SieFile[]
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMER_SIE_FILES,
    payload: {
      customer,
      sieFiles,
    },
  };
}
export function stateAssignmentManagers(
  customer: Customer,
  managers: PersonDetails[]
): CustomersAction {
  return {
    type: ActionTypes.SET_ASSIGNMENT_MANAGERS,
    payload: {
      customer,
      managers,
    },
  };
}

export function statePartialCustomer(
  customerNumber: string,
  update: Partial<Customer>
): CustomersAction {
  return {
    type: ActionTypes.UPDATE_CUSTOMER,
    payload: {
      update,
      customer_number: customerNumber,
    },
  };
}

export function stateLoadingNewCustomer(isLoading: boolean): CustomersAction {
  return {
    type: ActionTypes.LOADING_NEW_CUSTOMER,
    payload: isLoading,
  };
}

export function stateCustomerLoading(
  customer: Customer,
  isLoading: boolean
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMER_LOADING,
    payload: {
      isLoading,
      customer,
    },
  };
}

export function stateCustomerState(
  customer: Customer,
  state: Partial<CustomerState>
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMER_UI_STATE,
    payload: {
      customer,
      state,
    },
  };
}

export function stateUpdateDelivery(
  customer: Customer,
  deliveryId: string,
  update: Partial<CustomerDelivery>
): CustomersAction {
  return {
    type: ActionTypes.UPDATE_DELIVERY,
    payload: {
      update,
      deliveryId,
      customer,
    },
  };
}

export function stateCreateDelivery(
  customer: Customer,
  delivery: CustomerDelivery
): CustomersAction {
  return {
    type: ActionTypes.CREATE_DELIVERY,
    payload: {
      delivery,
      customer,
    },
  };
}

export function stateDeleteDelivery(
  customer: Customer,
  deliveryId: string
): CustomersAction {
  return {
    type: ActionTypes.DELETE_DELIVERY,
    payload: {
      deliveryId,
      customer,
    },
  };
}

export function stateDeleteDeliveriesBatch(
  customer: Customer,
  batchResponse: DeletedDelivery[]
): CustomersAction {
  return {
    type: ActionTypes.DELETE_DELIVERIES_BATCH,
    payload: {
      batchResponse,
      customer,
    },
  };
}

export function stateLoadingCustomersFailed(): CustomersAction {
  return {
    type: ActionTypes.LOADING_CUSTOMERS_FAILED,
  };
}

export function stateAppendCustomer(payload: Customer): CustomersAction {
  return {
    type: ActionTypes.APPEND_CUSTOMER,
    payload,
  };
}

export function stateLoadingCustomers(loading = true): CustomersAction {
  return {
    type: ActionTypes.LOADING_CUSTOMERS,
    payload: loading,
  };
}

export function stateCustomers(customers: CustomerState[]): CustomersAction {
  return {
    type: ActionTypes.LOAD_CUSTOMERS,
    payload: customers,
  };
}

export function stateCustomersLudvigServices(
  ludvigServices: CustomersLudvigServiceStatus[]
): CustomersAction {
  return {
    type: ActionTypes.SET_CUSTOMERS_LUDVIG_SERVICES,
    payload: ludvigServices,
  };
}

export function updateCustomersLudvigServices(
  ludvigServices: CustomersLudvigServiceStatus[]
): CustomersAction {
  return {
    type: ActionTypes.UPDATE_CUSTOMERS_LUDVIG_SERVICES,
    payload: ludvigServices,
  };
}
