import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Filter20Regular } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";

import AppConfig from "app-config";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutWrapper,
} from "components/layout/Layout";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { OverlaySpinner, SpinnerSize } from "components/spinner";
import { Content, ContentBody } from "components/content/Content";
import { TextButton } from "components/textButton/textButton";
import { useConfetti } from "hooks/use-confetti";
import { useTranslation } from "hooks/use-translate";
import { EKONOMI_BUSINESS_AREA } from "libs/constants";
import { numberFormat } from "libs/number-format";
import { useServiceMatrix } from "libs/service-matrix";
import { Deal } from "models/deals/deal";
import { Pipeline } from "models/deals/pipeline";
import { Stage } from "models/deals/stage";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";
import { fetchOfferTemplate, resetOffer } from "state/offer/offersThunks";
import {
  fetchBusinessOpportunities,
  fetchDealProperties,
  fetchPipelines,
  fetchProducts,
  moveDealToStage,
} from "state/sales/actions";
import { useAppDispatch } from "state/use-app-redux";
import { DealInformationRequiredFormModal } from "views/deals/components/DealInformationRequiredFormModal";
import { DealInformationRequiredFormTwoStepModal } from "views/deals/components/DealInformationRequiredFormTwoStepModal";
import { StageColumn } from "views/deals/components/stages/StageColumn";
import { DealsFiltersBox } from "views/deals/components/DealsFiltersBox";
import {
  FieldConfiguration,
  useFieldsConfiguration,
} from "views/deals/configuration/fields-config";
import { DealsFilterList } from "views/deals/components/DealsFiltersList";
import { useDealsFilters } from "views/deals/components/useDealsFilters";
import "./Deals.scss";

export const ItemTypes = {
  DEAL: "deal",
};

export type ActiveFilters = {
  selectedDateRange?: string;
  inboundOutboundDeals?: string;
};

export function Deals() {
  const { t: translate } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    deals,
    pipelines,
    stageConfiguration,
    offerTemplate,
    products,
    isLoadingProducts,
    dealOptions,
  } = useSelector((state: RootState) => ({
    deals: state.sales,
    pipelines: state.sales.pipelines,
    stageConfiguration: state.sales.stageConfiguration.data,
    offerTemplate: state.offers.offerTemplate.data,
    products: state.sales.products.data,
    isLoadingProducts: state.sales.products.isLoading,
    dealOptions: state.sales.dealOptions,
  }));

  const { confetti } = useConfetti();

  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({});
  const [filteredDeals, setFilteredDeals] = useState<Deal[]>([]);

  const [currentDealId, setCurrentDealId] = useState<string | undefined>(
    undefined
  );
  const [currentDealToStage, setDealToStage] = useState<Stage | undefined>(
    undefined
  );
  const [openInfoDialogFor, setOpenInfoDialogFor] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  const [companyNotFound, setCompanyNotFound] = useState(false);

  const { fields } = useFieldsConfiguration();
  const { filterDeals, filteredPipelines, getVisibleStagesSorted } =
    useDealsFilters({
      activeFilters,
      setFilteredDeals,
    });

  const sortedVisibleStages = getVisibleStagesSorted();

  const isLoadingDeals =
    deals.isLoading || dealOptions.isLoading || isLoadingProducts;

  const dealsBusinessAreas = dealOptions.data.find(
    (dealOption) => dealOption.name === "affarsomrade_deal_"
  );

  const { GetAllServiceGroupsFlattened } = useServiceMatrix();

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);
  const mappedServiceGroups = serviceGroups
    .filter((group) => group.name)
    .map((group) => group.name.toLowerCase());

  const clearCurrentDeal = () => {
    setCurrentDealId(undefined);
    setDealToStage(undefined);
  };

  useEffect(() => {
    const init = async () => {
      if (!pipelines.data.length) {
        dispatch(fetchPipelines());
      }

      if (!dealOptions.data.length) {
        dispatch(fetchDealProperties());
      }

      if (!products.length) {
        dispatch(fetchProducts());
      }
    };

    init();
    // adding the dependencies will call the dispatch functions multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getOfferTemplate = async () => {
      await dispatch(fetchOfferTemplate());
    };
    if (!offerTemplate) {
      getOfferTemplate();
    }
    dispatch(resetOffer());
  }, [offerTemplate, dispatch]);

  const onFinished = () => {
    const currentDeal = deals.data.find((deal) => deal.id === currentDealId);
    if (!currentDeal) {
      throw new Error("Could not find deal");
    }

    if (!currentDealToStage) {
      throw new Error("No selected stage");
    }
    moveDeal(currentDeal, currentDealToStage);
    clearCurrentDeal();
  };

  // moving deals that have services which exist in POG should be disabled
  const shouldDisableMovigStages = (deal: Deal) => {
    const mappedDealProducts = deal.productIds.map(
      (productId) =>
        products
          ?.find((product) => product.id === productId)
          ?.description?.toLowerCase() ?? ""
    );

    const disableMovingStages =
      dealsBusinessAreas?.options.find(
        // eslint-disable-next-line no-underscore-dangle
        (businessArea) => businessArea.value === deal.affarsomrade_deal_
      )?.value === EKONOMI_BUSINESS_AREA &&
      deal.productIds.length > 0 &&
      mappedDealProducts.some((productDescription) =>
        mappedServiceGroups.includes(productDescription.toLowerCase())
      );

    return disableMovingStages;
  };

  const isValidField = (field: FieldConfiguration, deal: Deal) => {
    if (!field) {
      throw new Error("Could not find the field configuration");
    }

    if (!field.isValid) {
      throw new Error("Field configuration missing isValid implementation");
    }

    return field.isValid(deal);
  };

  const dealHasAllRequiredDataForStage = (stage: Stage, deal: Deal) => {
    return stage.configuration && stage.configuration.RequiredFields
      ? stage.configuration.RequiredFields?.split(";").every(
          (stageRequiredField) => {
            const requiredField = fields.find(
              (field) => field.property === stageRequiredField
            );
            if (requiredField) {
              return isValidField(requiredField, deal);
            }
            return true;
          }
        )
      : true;
  };

  const isCurrentStage = (stageId: string, index: number) => {
    return (
      stageId === stageConfiguration[stageConfiguration.length - index].StageId
    );
  };

  const isLastStage = (stageId: string) => {
    return (
      stageId === stageConfiguration[stageConfiguration.length - 1].StageId
    );
  };

  const handleOnDragEnd = async (
    pipeline: Pipeline,
    { draggableId: dealId, destination }: DropResult
  ) => {
    const currentDeal = deals.data.find((deal) => deal.id === dealId);
    const stageId = destination?.droppableId;
    const stage = pipeline.stages.find((s) => s.id === stageId);

    if (!stage || !currentDeal || !stageId) {
      return;
    }

    // Deals that have at least one POG service can not be moved to
    // Business won and Contract sent manually
    // in moved to Business lost they cant be moved back to any stage
    if (
      shouldDisableMovigStages(currentDeal) &&
      (isLastStage(currentDeal.dealstage) ||
        isCurrentStage(stageId, 2) ||
        isCurrentStage(stageId, 3))
    ) {
      setOpenInfoDialogFor(currentDeal.dealstage);
      return;
    }

    if (
      currentDeal.organisationsnummer &&
      stage.configuration?.StageId === AppConfig.FEATURES.DEALS.DEAL_STAGE_WON
    ) {
      try {
        setIsLoading(true);
        const response = await dispatch(
          fetchCompanyInfoFromSales({
            searchByParam: SearchBy.OrgNumber,
            customerId: currentDeal.organisationsnummer,
          })
        );
        const company = unwrapResult(response);
        if (company) {
          onMoveDeal(currentDeal, stage);
        }
      } catch (err) {
        onMoveDeal(currentDeal, stage, true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      onMoveDeal(currentDeal, stage, !currentDeal.organisationsnummer);
    }
  };

  const onMoveDeal = (deal: Deal, stage: Stage, missingCompany = false) => {
    const shouldMoveToDealWon =
      stage.configuration?.StageId === AppConfig.FEATURES.DEALS.DEAL_STAGE_WON;
    if (
      !dealHasAllRequiredDataForStage(stage, deal) ||
      (shouldMoveToDealWon && missingCompany)
    ) {
      setCompanyNotFound(missingCompany);
      setCurrentDealId(deal.id);
      setDealToStage(stage);
    } else {
      moveDeal(deal, stage);
    }
  };

  const moveDeal = async (deal: Deal, stage: Stage) => {
    await dispatch(moveDealToStage(deal.id, stage.id)).then(async () => {
      if (
        stage.configuration?.StageId ===
          AppConfig.FEATURES.DEALS.DEAL_STAGE_WON &&
        companyNotFound
      ) {
        await dispatch(fetchBusinessOpportunities());
      }
    });
    if (!stage.configuration) {
      return;
    }

    if (stage.configuration.Confetti === "true") {
      confetti();
    }

    if (stage.configuration.onMove) {
      stage.configuration.onMove(deal);
    }
  };

  const currentDeal = deals.data.find((deal) => deal.id === currentDealId);

  useEffect(() => {
    if (deals.isLoading || deals.hasLoaded || deals.hasFailed) {
      filterDeals();
      return;
    }
    dispatch(fetchBusinessOpportunities());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deals.data, deals.hasFailed, deals.hasLoaded, deals.isLoading, dispatch]);

  useEffect(() => {
    filterDeals();
    // filter the deals on activeFilters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  return (
    <>
      {isLoading && <OverlaySpinner />}
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("MY_BUSINESS_OPPORTUNITIES")}</h1>
        </LayoutHeaderLeft>

        <LayoutHeaderRight>
          <div className="d-flex align-items-center">
            <DealsFilterList
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              setFilteredDeals={setFilteredDeals}
            />
            <button
              type="button"
              className={`filter-button px-xs d-flex align-items-center ml-md ${
                showFilters ? "active-state-bg" : ""
              }`}
              onClick={() => setShowFilters(!showFilters)}
            >
              <Filter20Regular className="mr-xs" />
              <span className="fw-600 px-xs">{translate("FILTER")}</span>
            </button>
            <div className="deals-vertical-divider mx-md" />
            <TextButton
              onClick={() => navigate(AppRouteHelper.getBusinessOpportunity())}
            >
              {translate("NEW_BUSINESS_OPPORTUNITY")}
            </TextButton>
          </div>
        </LayoutHeaderRight>
      </LayoutHeader>
      {showFilters && (
        <DealsFiltersBox
          setFilteredDeals={setFilteredDeals}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      )}
      <LayoutWrapper>
        <Content>
          <div className="contentHeader d-block">
            <h3 className="contentHeader--title">
              {translate("MY_BUSINESS_OPPORTUNITIES.HEADER.TITLE")}
            </h3>
          </div>
          <ContentBody>
            {currentDealToStage?.configuration?.RequiredFields &&
              (currentDealToStage?.configuration?.StageId ===
                AppConfig.FEATURES.DEALS.DEAL_STAGE_WON && companyNotFound ? (
                <DealInformationRequiredFormTwoStepModal
                  toStage={currentDealToStage}
                  deal={currentDeal}
                  isOpen={!!currentDeal}
                  onFinished={onFinished}
                  onDismiss={clearCurrentDeal}
                />
              ) : (
                <DealInformationRequiredFormModal
                  toStage={currentDealToStage}
                  deal={currentDeal}
                  isOpen={!!currentDeal}
                  onFinished={onFinished}
                  onDismiss={clearCurrentDeal}
                />
              ))}

            {!pipelines.isLoading && filteredPipelines.length === 0 && (
              <p>{translate("DEAL_STAGES_CURRENTLY_HIDDEN")}</p>
            )}

            {filteredPipelines.map((pipeline) => {
              return (
                <DragDropContext
                  key={pipeline.id}
                  onDragEnd={(drag) => {
                    handleOnDragEnd(pipeline, drag);
                  }}
                >
                  <table className="deals-table top-align">
                    <thead>
                      <tr>
                        {sortedVisibleStages.map((stage) => (
                          <th key={stage.id} className="stage-column">
                            {stage.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="border-collapse-separate">
                      <tr>
                        {sortedVisibleStages.map((stage) => {
                          const stageDeals = filteredDeals.filter(
                            (deal) => deal.dealstage === stage.id
                          );
                          return (
                            <StageColumn
                              key={stage.id}
                              stageName={stage.id}
                              deals={stageDeals}
                            />
                          );
                        })}
                      </tr>

                      <tr>
                        {sortedVisibleStages.map((stage) => {
                          const stageDeals = filteredDeals.filter(
                            (deal) => deal.dealstage === stage.id
                          );

                          return (
                            <td
                              key={stage.id}
                              className="stage-column border-top"
                            >
                              <div className="d-flex justify-content-between">
                                {translate("TOTAL_VALUE")}
                                <div className="ml-sm fw-bold text-wrap">
                                  {`${numberFormat(
                                    stageDeals.reduce(
                                      (sum, current) =>
                                        sum + (current.amount || 0),
                                      0
                                    )
                                  )} SEK`}
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </DragDropContext>
              );
            })}

            {(isLoadingDeals || pipelines.isLoading) && (
              <Spinner
                label={translate("MY_BUSINESS_OPPORTUNITIES.LOADING")}
                size={SpinnerSize.Small}
              />
            )}
          </ContentBody>
        </Content>
      </LayoutWrapper>
      {!!openInfoDialogFor && (
        <Modal
          isOpen={!!openInfoDialogFor}
          onDismiss={() => setOpenInfoDialogFor("")}
          size="medium"
          header={<ModalHeader headerTitleText="DEALS_INFORMATION" />}
          footer={
            <ModalFooter
              onSave={() => setOpenInfoDialogFor("")}
              labelSubmit="CLOSE_MODAL"
            />
          }
        >
          <div>
            <div className="d-flex px-md">
              <span className="p-sm">
                {isLastStage(openInfoDialogFor)
                  ? translate("DEALS_DISALBED_MOVING")
                  : translate("DEALS_DISALBED_MOVING_LONG")}
              </span>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
